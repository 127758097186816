import React from "react";
import Home from "./Home/Home";

type RedirectProps = {
    href: string;
}

const Redirect = (props: RedirectProps) => {
    window.location.href = props.href; 
    return <Home />;
}

export default Redirect;