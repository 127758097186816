import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import { Link, Outlet } from "react-router-dom";

import logo from '../../assets/logo.png';
import './NavBar.css'


const NavBar = () => {

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <Link to={'/'}>
                            <img src={logo} className='NavBarIcon' alt='logo' />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link>
                                <Link to={'/imprint'} className='nav-link'>
                                    Impressum
                                </Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to={'/data-privacy'} className='nav-link'>
                                    Datenschutz
                                </Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to={'/poll'} className='nav-link'>
                                    Umfrage
                                </Link>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </>

    )
}

export default NavBar;