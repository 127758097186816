import React from "react";
import TypeIt from "typeit-react";
import logo from '../../assets/logo_with_name.png';
import './Home.css'

const Home = () => {
    return (
        <div className="HomeRoot">
            <img src={logo} className='App-logo' alt='logo' />
            <div className='App-Desc'>
                <TypeIt options={{
                    strings: [
                        'Die eine App für deinen Hund!',
                        'Coming soon...'
                    ],
                }} />
            </div>
        </div>
    )
}

export default Home;