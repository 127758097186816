import React, { useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import {
  Route,
  Routes,
  useNavigate,

} from "react-router-dom";

import Imprint from './pages/Imprint/Imprint';
import Home from './pages/Home/Home';
import * as queryString from 'query-string'
import Redirect from './pages/Redirect';
import DataPrivacy from './pages/DataPrivacy/DataPrivacy';

const POLL_URL = 'https://umfrage.phi-dog.de';

function App() {

  let navigate = useNavigate();

  useEffect(() => {
    const params = queryString.parse(document.location.search);
    const redirect = params.redirect;
    console.log(redirect);
    if (document.location.pathname === '/' && redirect) {
      navigate(redirect as string, { replace: true });
    }
  })

  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route index element={<Home />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path='/poll' element={<Redirect href={POLL_URL}/>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

