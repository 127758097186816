import React from "react";
import './Imprint.css'

const Imprint = () => {

    const toHtml = (text: string) => {
        return text.split('\n').map(part => (
            <>
                {part}
                <br/>
            </>
        ));
    }

    return (
        <div className="imprint-root">
            <div className="section-title">
                Impressum: 
            </div>
            <div>
                {toHtml(IMPRINT_CONTENT)}
            </div>

            <div className="section-title">
                Kontakt: 
            </div>
            <div>
                {toHtml(CONTACT_CONTENT)}
            </div>

            <div className="section-title">
                Haftungsausschluss: 
            </div>
            <div>
                {toHtml(DISCLAIMER_CONTENT)}
            </div>
        </div>
    )
}

export default Imprint;

const IMPRINT_CONTENT = `
Angaben gemäß § 5 TMG 
Karsten Grabis
Neindorfer Str. 19
38300 Wolfenbüttel
Telefon: +49 175 2141256
`

const CONTACT_CONTENT = `
E-Mail: karsten@phi-dog.de (Für Fragen und Informationen zu Inhalten unserer App und
rechtliche Belange)
E-Mail: niklas@phi-dog.de (Für techn. Belange rund um unsere App)
`

const DISCLAIMER_CONTENT = `
Haftung für Inhalte
Die Inhalte wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
Aktualität können wir jedoch keine Gewähr übernehmen.
Da wir weder Tierärzte oder Fachexperten sind, solltest du für alle Fragen zu deinem Hund
immer Rücksprache mit dem Tierarzt deines Vertrauens halten.
Unsere Angaben sind alle mit großer Sorgfalt recherchiert, haben aber keinen Anspruch auf
Richtigkeit.
Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter
jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
hinweisen.
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
umgehend entfernen.

Haftung für Links
Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
Einfluss haben.
Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
erkennbar.
Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
Rechtsverletzungen werden wir derartige Links umgehend entfernen.
Urheberrecht

Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
Zustimmung des jeweiligen Autors bzw. Erstellers.
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
gestattet.
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.

Datenschutz
Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten
behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
Werbeinformationen wie etwa Spam-Mails vor.

`